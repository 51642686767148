



















































































































































































































































































































import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import DropDown from "@/components/common/Inputs/DropDown.vue";
import Navbar from "@/components/common/Navbar.vue";
import ExcelUploadButton from "@/components/common/Buttons/ExcelUploadButton.vue";
import ListItem from "@/components/common/List/ListItem.vue";
import ListItemContent from "@/components/common/List/ListItemContent.vue";
import SideDrawer from "@/components/common/SideDrawer.vue";
import Illustration from "@/components/common/Illustration.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import DistributorService from "@/services/DistributorService";
import AssetManagementService from "@/services/AssetManagementService";
import DetailsCard from "@/components/common/DetailsCard.vue";
import SearchBar from "@/components/common/SearchBar.vue";
import SearchableList from "@/components/common/SearchableList.vue";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { IDistributor } from "@/interfaces/positioncode";
import { IAsset, IConfidencelevel } from "@/interfaces/asset";
import { ILink, ITableHeader } from "@/interfaces/common";
import { VLayout } from "vuetify/lib";

@Component({
  components: {
    SearchBar,
    Illustration,
    Navbar,
    ExcelUploadButton,
    SideDrawer,
    Snackbar,
    DetailsCard,
    DropDown,
    SearchableList,
    ActionablePopup,
    DataTable,
    TextButton,
    ListItem,
    ListItemContent,
  },
})
export default class Create extends Vue {
  // for illustration
  private description: string = "No Asset Found";
  private subdescription: string = "Please create a Asset to begin with";
  //for navbar
  private sidedrawerTitle: string = "Fill Asset Details";
  //for side drawer
  private sideDrawer: boolean = false;
   private sidedrawerTitle1: string = "Fill Asset Rule";
  //for side drawer
  private sideDrawer1: boolean = false;
  private valid: boolean = false;
  private isEdit: boolean = false;
  private validrule: boolean = false;
  private timeout!: NodeJS.Timeout;
  private message: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";
  private showAssetList: boolean = true;
  //flags for create or edit check
  private createAsset: boolean = true;
  private createAuditingRule: boolean = false;
  private menuItems: Array<ILink> = [];
  private selectedAssetId: number = 0;
  private assetSearch: string = "";
  private asset: Array<IAsset> = [];
  private totalAsset: number = 0;
  private pages: number = 0;
  private page: number = 1;
  private AssetCategory = [
    {
      value: "Cigarette Cases",
      key: 0,
    },
    {
      value: "Chocolate Boxes",
      key: 1,
    },
    {
      value: "Refrigerator",
      key: 2,
    },
    {
      value: "Freezer",
      key: 3,
    },
  ];
  private AssetLoading: boolean = false;
  private selectedActionMenu: number = -1;
  private editedItem: IAsset = {
    name: "",
    type: "",
    valueCapacity: 0,
    id: 0,
    volumeCapacity: 0,
  };
  private defaultItem: IAsset = {
    name: "",
    type: "",
    valueCapacity: 0,
    id: 0,
    volumeCapacity: 0,
  };
  private viwedrule: IConfidencelevel = {
    id:0,
    highLimit: "",
    mediumLimit: "",
  };
  private confirmationPopup: boolean = false;
  private confirmationPopupTitle: string = "";
  private confirmationPopupSubtitle: string = "";
  private confirmationPopupText: string = "";
  private cancelBtnText: string = "";
  private confirmBtnText: string = "";
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Name",
      value: "name",
      sortable: false,
      default: "",
    },
    {
      text: "Type",
      value: "type",
      sortable: false,
      default: "",
    },
    {
      text: "Value Capacity",
      value: "valueCapacity",
      sortable: false,
      default: "VACANT",
    },
    {
      text: "Volume Capacity",
      value: "volumeCapacity",
      sortable: false,
      default: "VACANT",
    },
    {
      text: "Actions",
      value: "action",
      sortable: false,
      default: "",
    },
  ];
  private mediumlimitdeavtivate: boolean=true;
  private inactiveAsset: boolean = false;
  private creatingOrUpdatingAsset: boolean = false;
  private creatingOrUpdatingAssetRule: boolean = false;

  
  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }

  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }

  private createOrUpdateAsset(): void {
    if (!this.creatingOrUpdatingAsset)
      this.createAsset ? this.createNewAsset() : this.updateAsset();
  }
  private createOrUpdateAssetRule(): void {
    var x: number = +this.viwedrule.highLimit;
    var y: number = +this.viwedrule.mediumLimit;
    if(x<y)
       this.createNewAssetRule();
    
    else {
      this.snackbarColor = "red";
          this.snackbar = true;
          this.message = "The value for medium confidence should be greater than high confidence.";
    }
  }
   public createNewAssetRule(): void {
    let value = Object.assign({}, this.viwedrule);
    this.creatingOrUpdatingAsset = true;
    AssetManagementService.createAssetRule({
      id:0,
      HighLimit: value.highLimit,
      MediumLimit:value.mediumLimit,      
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showAssetList = true;
        this.creatingOrUpdatingAsset = false;
        this.closeSideDrawerRule(false);
        this.clearForm();
        this.refreshVanList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingAsset = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }

  public menuAction(asset: IAsset, commandIndex: number) {
    this.selectedAssetId = asset.id;
    this.selectedActionMenu = commandIndex;
    switch (this.menuItems[commandIndex].Text) {
      case "DEACTIVATE":
        this.confirmationPopupTitle = "Confirm Deactivation";
        this.confirmationPopupSubtitle =
          "Are you sure you wish to deactivate the Asset?";
        this.confirmationPopupText = "";
        this.cancelBtnText = "Cancel";
        this.confirmBtnText = "Confirm";
        this.confirmationPopup = true;
        break;
      default:
        console.log("Unhandled menuAction");
    }
  }
  public activateAsset(asset: IAsset) {
    this.selectedAssetId = asset.id;
    this.confirmationPopupTitle = "Confirm Activation";
    this.confirmationPopupSubtitle = asset.name + " asset will be activated.";
    this.confirmationPopupText = "";
    this.cancelBtnText = "Keep Inactive";
    this.confirmBtnText = "Activate";
    this.confirmationPopup = true;
  }
  public editAsset(value: IAsset): void {
    this.createAsset = false;
    this.openDrawer("");
    this.editedItem = Object.assign(
      {},
      {
        name: value.name,
        type: value.type,
        valueCapacity: value.valueCapacity,
        volumeCapacity: value.volumeCapacity,
        id: value.id,
      }
    );
  }
  public updateAsset(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingAsset = true;
    AssetManagementService.updateAsset({
      name: value.name,
      type: value.type,
      valueCapacity: value.valueCapacity,
      volumeCapacity: value.volumeCapacity,
      id: value.id,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showAssetList = true;
        this.creatingOrUpdatingAsset = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshVanList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingAsset = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public createNewAsset(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingAsset = true;
    AssetManagementService.createAsset({
      name: value.name,
      type: value.type,
      valueCapacity: value.valueCapacity,
      volumeCapacity: value.volumeCapacity,
      id: value.id,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showAssetList = true;
        this.creatingOrUpdatingAsset = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshVanList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingAsset = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public clearForm(): void {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
  public async refreshVanList() {
    this.inactiveAsset
      ? await this.getInactiveAsset(this.assetSearch, 1)
      : await this.getAsset(this.assetSearch, 1);
  }
  public openDrawer(value: string): void {
    this.sidedrawerTitle = this.createAsset
      ? "Fill Asset Details"
      : "Edit Asset Details";
    this.clearForm();
    this.sideDrawer = true;
    this.formResetValidation.resetValidation();
  }
  public closeSideDrawer(value: boolean): void {
    this.hideWindowScrollBar(value);
    this.sideDrawer = value;
    //this.createAuditingRule=false;
  }
  public openDrawerRule(value: string): void {
    this.sidedrawerTitle1 = this.createAuditingRule
      ? "Fill Asset Rule"
      : "Edit Asset Rule";
    this.AssignValueRule();
    this.sideDrawer1 = true;
    this.formResetValidation.resetValidation();
  }
  public closeSideDrawerRule(value: boolean): void {
    this.hideWindowScrollBar(value);
    this.sideDrawer1 = value;
  }
  
    public async AssignValueRule(): Promise<boolean>{
           return new Promise<boolean>(res => {
                AssetManagementService.GetAssetRule().then((response) => {
                    this.viwedrule = response.data.assetsrule;
                    res(true);
                }).catch((error) : any => {
                    this.showCommunicationError(error);
                    console.log(error);
                    
                })
            })
        }
  
  private getRefAsAny(refKey: string) {
    return this.$refs[refKey] as any;
  }

  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }

  public async getAsset(searchTerm: string, value: number): Promise<boolean> {
    this.AssetLoading = true;
    return new Promise<boolean>((res) => {
      AssetManagementService.GetAsset(this.assetSearch, value)
        .then(
          (response: {
            data: { asset: IAsset[]; pages: number; total: number };
          }) => {
            this.asset = response.data.asset;

            this.pages = response.data.pages;
            this.totalAsset = response.data.total;
            this.AssetLoading = false;
            if (this.asset.length > 0) {
              this.showAssetList = true;
            } else {
              this.showAssetList = false;
              this.description =
                this.assetSearch.length == 0
                  ? "No Asset Found"
                  : "No matching result found";
              this.subdescription =
                this.assetSearch.length == 0
                  ? "Please create a Asset to begin with"
                  : "Make sure you typed correctly or try finding in inactive Asset";
            }
            res(true);
          }
        )
        .catch((error: any) => {
          this.showCommunicationError(error);
          console.log(error);
          this.AssetLoading = false;
        });
    });
  }

  public changePage(value: number): void {
    this.page = value;
    this.inactiveAsset
      ? this.getInactiveAsset(this.assetSearch, value)
      : this.getAsset(this.assetSearch, value);
  }

  public confirmAction(value: any): void {
    if (this.inactiveAsset) {
      AssetManagementService.activateAsset(value)
        .then((res: any) => {
          this.snackbarColor = "success";
          this.message = "Changes Saved Successfully";
          this.actionablePopup();
          this.switchAsset();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    } else {
      AssetManagementService.deactivateAsset(value)
        .then((res: any) => {
          this.snackbarColor = "red";
          this.message = "Asset deactivated and moved to inactive list";
          this.actionablePopup();
          this.refreshVanList();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    }
  }
  public actionablePopup() {
    this.snackbar = true;
    this.confirmationPopup = false;
    this.showAssetList = true;
  }

  private async GetMenuActions(): Promise<void> {
    this.menuItems = [
      {
        Text: "DEACTIVATE",
        Color: "red",
      },
    ];
  }

  public async getInactiveAsset(
    searchTerm: string,
    page: number
  ): Promise<boolean> {
    this.AssetLoading = true;
    return new Promise<boolean>((res) => {
      AssetManagementService.getInactiveAsset(searchTerm, page)
        .then(
          (response: {
            data: { asset: IAsset[]; pages: number; total: number };
          }) => {
            this.asset = response.data.asset;
            this.pages = response.data.pages;
            this.totalAsset = response.data.total;
            this.AssetLoading = false;
            res(true);
          }
        )
        .catch((error: any) => {
          this.showCommunicationError(error);
          console.log(error);
          this.AssetLoading = false;
        });
    });
  }
  public switchAsset() {
    if (this.inactiveAsset) {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: false,
          default: "",
        },
        {
          text: "Type",
          value: "type",
          sortable: false,
          default: "",
        },
        {
          text: "Value Capacity",
          value: "valueCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Volume Capacity",
          value: "volumeCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.showAssetList = true;
      this.getInactiveAsset(this.assetSearch, 1);
    } else {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: false,
          default: "",
        },
        {
          text: "Type",
          value: "type",
          sortable: false,
          default: "",
        },
        {
          text: "Value Capacity",
          value: "valueCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Volume Capacity",
          value: "volumeCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getAsset(this.assetSearch, 1);
    }
  }
  async created() {
    this.AssetLoading = true;
    this.getAsset(this.assetSearch, 1);
    this.GetMenuActions().then((_) => _);
  }

  private hideWindowScrollBar(hideIt: boolean) {
    window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
}
