import client from './../apiService';

class AssetManagementService{
    async GetAsset(searchTerm: string, page: number){
        const url = `Asset/GetAsset?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
    async GetAssetRule(){
        const url = `Asset/GetAssetRule`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }

    deactivateAsset(assetId: number){
        const url = `Asset/DeactivateAsset?AssetId=${assetId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateAsset(assetId: number){
        const url = `Asset/ActivateAsset?AssetId=${assetId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveAsset(searchTerm: string, page: number){
        const url = `Asset/GetInactiveAssets?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    updateAsset(asset: object){
        const url = `Asset/UpdateAsset`;
        return client.post(url, asset, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    createAsset(asset: object){
        const url = `Asset/CreateAsset`;
        return client.post(url, asset, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    createAssetRule(assetRule: object){
        const url = `Asset/CreateAssetRule`;
        return client.post(url, assetRule, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

}

export default new AssetManagementService();